import ApiService from "@/core/services/ApiService";
import { processErrors } from "./errorsProcessing";

const url = "api/v1/user";

export async function searchPagination(request) {
  return await ApiService.post(url + "/searchPagination", request as never)
    .then((res) => {
      const data = res.data;
      return data;
    })
    .catch(({ response }) => {
      processErrors(response);
    });
}

export async function searchUsers(request) {
  return await ApiService.post(url + "/search", request as never)
    .then((res) => {
      const data = res.data;
      return data;
    })
    .catch(({ response }) => {
      processErrors(response);
    });
}
