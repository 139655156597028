import ApiService from "@/core/services/ApiService";
import { processErrors } from "./errorsProcessing";

const url = "api/v1/note";

export async function searchNotes(request) {
  return await ApiService.post(url + "/search", request as never)
    .then((res) => {
      const data = res.data;
      return data;
    })
    .catch(({ response }) => {
      processErrors(response);
    });
}

export async function addNote(note) {
  await ApiService.post(url, note as never)
    .then()
    .catch(({ response }) => {
      processErrors(response);
    });
}

export async function updateNote(note) {
  if (note) {
    return await ApiService.put(url + "/" + note.id, note as never)
      .then()
      .catch(({ response }) => {
        processErrors(response);
      });
  }
}

export async function deleteNote(note) {
  if (note) {
    return await ApiService.delete(url + "/" + note?.id)
      .then()
      .catch(({ response }) => {
        processErrors(response);
      });
  }
}