export function getNow() {
  const date = new Date();
  date.setHours(0, 0, 0, 0);
  return date;
}

export function getUTCnow() {
  const date = new Date();
  const d = new Date(
    Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())
  );
  return d;
}

export function roundNumber(num: number, scale: number) {
  if (!("" + num).includes("e")) {
    return +(Math.round(Number(num + "e+" + scale)) + "e-" + scale);
  } else {
    const arr = ("" + num).split("e");
    let sig = "";
    if (+arr[1] + scale > 0) {
      sig = "+";
    }
    return +(
      Math.round(Number(+arr[0] + "e" + sig + (+arr[1] + scale))) +
      "e-" +
      scale
    );
  }
}

export const epsilon = 0.0000001;

//TEMP
export const positions = 4;

export const sumNumbers = (a, b) => {
  if (!a) a = 0;
  if (!b) b = 0;
  const factor = Math.pow(10, positions);
  return (
    (a.toFixed(positions) * factor + b.toFixed(positions) * factor) / factor
  );
};

export const subNumbers = (a, b) => {
  if (!a) a = 0;
  if (!b) b = 0;
  const factor = Math.pow(10, positions);
  return (
    (a.toFixed(positions) * factor - b.toFixed(positions) * factor) / factor
  );
};

